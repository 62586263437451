<script setup>
    /**
    * (prop) position: 'top' | 'bottom' | 'right' | 'left'
    * (prop) hasRouter: true | false (if true canvas will close when routing to a different route)
    * To close offcanvas programatically, add data-bs-dismiss="offcanvas" to your html
    * offcanvas component, check snippets how to use open buttons
    * @example
        <button class="btn btn-outline-primary me-2" v-target:canvas>OffCanvas</button>
        <OffCanvas name="canvas" ref="canvas" position="right">
            <div class="offcanvas-header border-b border">
                <h5 class="offcanvas-title" id="offcanvasRightLabel">Menu</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                test
            </div>
        </OffCanvas>
    * @definition
    **/
    import { ref, nextTick, provide, inject, onBeforeUnmount, computed, watch } from 'vue';
    import useTarget from 'o365.vue.composables.target.ts';
    import { nestedStackKey } from 'o365.modules.vue.injectionKeys.js';
    import useNestedStack from 'o365.vue.composables.NestedStack.ts';
    import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';
    import vueRouter from 'vue-router';

    const props = defineProps({
        title: String,
        name: String,
        to: {
            type: String,
            required: false,
            default: 'body'
        },
        offcanvasOptions: {
            type: Object,
            required: false
        },
        class: [String, Object],
        disableTeleport: {
            type: Boolean,
            required: false,
            default: false
        },
        alwaysRender: Boolean,
        //movable: {
        //    type: Boolean,
        //    required: false,
        //    default: false
        //},
        position: {
            type: String,
            required: false,
            default: "right"
        },
    });

    const position = computed(() => {
        switch (props.position) {
            case "top":
                return "offcanvas-top"
            case "bottom":
                return "offcanvas-bottom"
            case "right":
                return "offcanvas-end"
            case "left":
                return "offcanvas-start"
            default:
                return "offcanvas-end"
        };
    });
    const emit = defineEmits([
        /**
        * The event is emitted when the offcanvas is shown and the css transactions have finished
        * @param {Event} event The event returned by hidden.bs.offcanvas listener
        */
        'shown',
        /**
        * The event is emitted when offcanvas is transitioning to show
        * @param {Event} event The event returned by show.bs.offcanvas listener
        */
        'show',
        /**
        * The event is emitted when the offcanvas is hidden and the css transactions have finished
        * @param {Event} event The event returned by shown.bs.offcanvas listener
        */
        'hidden'
    ]);

    if (props.name) {
        const instance = Math.round(Math.random()*100);
        useTarget({
            name: props.name,
            handler: () => show(),
        });
    }

    //--- Nested dropdown logic ---
    const { currentNest, add: addToNest, remove: removeFromNest } = useNestedStack({
        injectinoKey: nestedStackKey,
    });

    const offcanvas = ref(null);
    const canvasRef = ref(null);

    const renderCanvas = ref(false);

    async function setupoffcanvas() {
        renderCanvas.value = true; 
        await nextTick();
        offcanvas.value = bootstrap.Offcanvas.getOrCreateInstance(canvasRef.value, props.movable ? { backdrop: false ,...(props.offcanvasOptions??{})} : props.offcanvasOptions);
        addToNest(canvasRef.value);

        offcanvasHasDataGrid = !!canvasRef.value.querySelector('.o365-data-grid');

        canvasRef.value.addEventListener('show.bs.offcanvas', onBsShow);
        canvasRef.value.addEventListener('shown.bs.offcanvas', onBsShown);
        canvasRef.value.addEventListener('hidden.bs.offcanvas', onBsHidden);
    }

    if (props.alwaysRender) {
        setupoffcanvas();
    }

    let offcanvasHasDataGrid = false;
    
    async function show() {
        if (!renderCanvas.value) {
            await setupoffcanvas();
        }
        if(offcanvas.value) offcanvas.value.show();
    }

    function hide(awaitNextTick = true) {
        if (offcanvas.value) {
            if (offcanvas.value._isTransitioning) {
                const shownEventHandler = () => {
                    canvasRef.value.removeEventListener('shown.bs.offcanvas', shownEventHandler);
                    canvasRef.value.removeEventListener('hidden.bs.offcanvas', hiddenEventHandler);
                    offcanvas.value.hide();
                };

                const hiddenEventHandler = () => {
                    canvasRef.value.removeEventListener('shown.bs.offcanvas', shownEventHandler);
                    canvasRef.value.removeEventListener('hidden.bs.offcanvas', hiddenEventHandler);
                }

                canvasRef.value.addEventListener('shown.bs.offcanvas', shownEventHandler);
                canvasRef.value.addEventListener('hidden.bs.offcanvas', hiddenEventHandler);
            } else {
                offcanvas.value.hide();
            }
        } else if (renderCanvas.value && awaitNextTick) {
            nextTick().then(() => {
                hide(false);
            });
        }
    }

    const onBsShow = (e) => {
        emit('show', e);
    };

    const onBsShown = (e) => {
        if (offcanvasHasDataGrid) {
            window.dispatchEvent(new Event('resize'));
        }

        emit('shown', e);
    }

    const onBsHidden = (e) => {
        emit('hidden', e);
    }


    onBeforeUnmount(() => {
        if (renderCanvas.value) {
            removeFromNest();

            canvasRef.value.removeEventListener('shown.bs.offcanvas', onBsShown);
            canvasRef.value.removeEventListener('show.bs.offcanvas', onBsShow);
            canvasRef.value.removeEventListener('hidden.bs.offcanvas', onBsHidden);
            if (offcanvas.value) {
                if (offcanvas.value._backdrop?._isAppended && offcanvas.value?._backdrop?._element?.remove) {
                    offcanvas.value._backdrop._element.remove();
                }
                //offcanvas.value.dispose();
            }
        }
    });
    defineExpose({offcanvas, show, hide});
</script>

<template>
    <Teleport v-if="renderCanvas" :to="to" :disabled="disableTeleport">
        <OMediaQueryProvider v-slot="{ xs }">
            <div ref="canvasRef" class="offcanvas" :class="position" v-bind="$attrs" v-if="renderCanvas">
                <div v-if="title" class="offcanvas-header">
                    <div class="offcanvas-content">
                        <div class="offcanvas-header" :class="{ 'flex-column': xs }">
                            <template v-if="xs">
                                <div class="d-flex align-items-center w-100">
                                    <h5 class="offcanvas-title">{{ title }}</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>

                                <!-- @slot
                                @ignore -->
                                <slot name="headerAction"></slot>
                            </template>

                            <template v-else>
                                <h5 class="offcanvas-title">{{ title }}</h5>
                                <!-- @slot
                                @ignore -->
                                <slot name="headerAction"></slot>
                                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </template>
                        </div>
                        <slot></slot>
                    </div>
                </div>
                <slot v-else></slot>
            </div>
        </OMediaQueryProvider>
    </Teleport>
</template>